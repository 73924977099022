/* eslint-disable no-unused-vars */
import { useEffect, useState } from "react";
// import { Switch, Route } from "react-router-dom";
// import Home from "./pages/home";
import "./App.css";
import Web3 from "web3";
import { contractAbi, contractAddress } from "./config";
import {
  contractAbi_marketplace,
  contractAddress_marketplace,
} from "./marketContract";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import InformationModal from "./components/informationModal";
import ConfirmationLoadingPopup from "./components/confirmationLoadingPopup";
import axios from "axios";
import Header from "./components/Header";
import Main from "./components/Main";
import Footer from "./components/Footer";
toast.configure();
function App() {
  const [chainId, setChainId] = useState(null);
  const [account, setAccount] = useState(null);
  const [contract, setContract] = useState(null);
  const [marketContract, setMarketContract] = useState(null);

  const [totalSupply, setTotalSupply] = useState(0);
  const [maxSupply, setMaxSupply] = useState(0);
  const [price, setPrice] = useState(0);
  const [displayPrice, setDisplayPrice] = useState(0);
  const [lessMintAmountAlert, setLessMintAmountAlert] = useState(false);
  const [accessAccountDenied, setAccessAccountDenied] = useState(false);
  const [installEthereum, setInstallEthereum] = useState(false);
  const [nftMinted, setNftMinted] = useState(false);
  const [nftMinting, setNftMinting] = useState(false);
  const [transactionRejected, setTransactionRejected] = useState(false);
  const [transactionFailed, setTransactionFailed] = useState(false);
  const [switchToMainnet, setswitchToMainnet] = useState(false);
  const [ethereumCompatibleBrowser, setEthereumCompatibleBrowser] =
    useState(false);
  const [mintingInProgress, setMintingInProgress] = useState(false);
  const [confirmTransaction, setConfirmTransaction] = useState(false);
  const [saleLive, setSaleLive] = useState(false);
  const [info, setInfo] = useState([]);

  const CONNECTABLE_CHAIN = 56;
  const BASE_URL = "https://defi.mobiwebsolutionz.com/api/forceofnature";
  async function loadWeb3() {
    if (window.ethereum) {
      window.web3 = new Web3(window.ethereum);
      try {
        loadBlockchainData();
        getCurrentAddressConnected();
        addAccountsAndChainListener();
        const accounts = await window.ethereum.request({
          method: "eth_requestAccounts",
        });
        setAccount(accounts[0]);
      } catch (error) {
        if (error.code === 4001) {
          setAccessAccountDenied(true);
        } else console.error(error);
      }
    } else {
      setInstallEthereum(true);
    }
  }
  const changeNetwork = async () => {
    if (window.ethereum) {
      try {
        await window.ethereum.request({
          method: "wallet_switchEthereumChain",
          params: [{ chainId: Web3.utils.toHex(CONNECTABLE_CHAIN) }],
        });
      } catch (error) {
        console.error(error);
      }
    }
  };
  useEffect(() => {
    if (chainId !== CONNECTABLE_CHAIN) {
      changeNetwork();
    }
  }, [chainId]);

  useEffect(() => {
    loadWeb3();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const loadBlockchainData = async () => {
    const contract = new window.web3.eth.Contract(contractAbi, contractAddress);
    setContract(contract);
    const marketContract = new window.web3.eth.Contract(
      contractAbi_marketplace,
      contractAddress_marketplace
    );
    setMarketContract(marketContract);
    const chainId = await window.web3.eth.getChainId();
    setChainId(chainId);
    //success when chainId = 4 else failure
    // you are connected to main net
    // Please connect to main net

    if (chainId === CONNECTABLE_CHAIN) {
      toast(`You are connected to main net`, {
        type: "success",
        position: toast.POSITION.BOTTOM_CENTER,
      });
      const totalSupply = await contract.methods.totalSupply().call();
      setTotalSupply(totalSupply);

      const price = await contract.methods.getPrice().call();
      setPrice(price);
      const displayPrice = window.web3.utils.fromWei(price, "ether");
      setDisplayPrice(displayPrice);
      const MAX_SUPPlY = await contract.methods.MAX_SUPPLY().call();
      // console.log("MAX_SUPPLY:", MAX_SUPPlY);
      setMaxSupply(MAX_SUPPlY);

      const nftArrays = [];
      console.log(totalSupply);
      for (let i = 0; i < totalSupply; i++) {
        console.log("I", i + 1);
        try {
          const token = await marketContract.methods
            .getNFTUri(contractAddress, i + 1)
            .call();
          console.log("token:", token);
          nftArrays.push(token);
        } catch (error) {
          console.log(error);
        }
      }

      if (!info.length) {
        nftArrays.forEach(async (nft, i) => {
          // console.log("from For EACH", i);

          if (chainId === CONNECTABLE_CHAIN) {
            const { data } = await axios.get(nft);
            // console.log(data);
            // console.log("JSON DATA", data.name, data.animation_url);
            const owner = await marketContract.methods
              .getNFTowner(contractAddress, i + 1)
              .call();
            console.log("owner", owner);
            try {
              const buydata = await axios.get(
                `${BASE_URL}/get-nft-details.php?contractAddress=${contractAddress}&tokenId=${
                  i + 1
                }`
              );
              console.log("BUY DATA:", buydata);
              setInfo((prev) => [...prev, { ...data, owner: owner, buydata }]);
              if (buydata.data.status === "error") {
                console.log("JSON DATA", data.name, data.animation_url);
                const { animation_url } = data;
                try {
                  const sendData = await axios.post(`${BASE_URL}/add-nft.php`, {
                    currentOwner: owner,
                    contractAddress: contractAddress,
                    tokenId: i + 1,
                    name: data.name,
                    image: animation_url,
                    network: "testnet",
                    // description: data.discription,
                    attributes: JSON.stringify(data.attribute),
                  });

                  console.log("POST_DATA", sendData);
                } catch (error) {
                  console.log(error);
                }
              }
            } catch (error) {
              console.log(error);
            }
          }
        });
      }
      //event will be fired by the smart contract when a new NFT is minted
      contract.events
        .NFTMinted()
        .on("data", async function (result) {
          setTotalSupply(result.returnValues[0]);
        })
        .on("error", console.error);
    } else {
      toast("Please connect to main net", {
        type: "error",
        position: toast.POSITION.BOTTOM_CENTER,
      });
    }
  };

  const getCurrentAddressConnected = async () => {
    try {
      const accounts = await window.ethereum.request({
        method: "eth_accounts",
      });
      if (accounts.length > 0) {
        setAccount(accounts[0]);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const addAccountsAndChainListener = async () => {
    //this event will be emitted when the currently connected chain changes.
    window.ethereum.on("chainChanged", (_chainId) => {
      window.location.reload();
    });

    // this event will be emitted whenever the user's exposed account address changes.
    window.ethereum.on("accountsChanged", (accounts) => {
      window.location.reload();
    });
  };

  async function mint(mintCount) {
    if (contract) {
      if (chainId === CONNECTABLE_CHAIN) {
        const saleOpen = await contract.methods.saleOpen().call();
        if (saleOpen) {
          if (mintCount === 0) {
            setLessMintAmountAlert(true);
          } else {
            setConfirmTransaction(true);
            const finalPrice = Number(price) * mintCount;
            contract.methods
              .mintNFT(mintCount)
              .send({ from: account, value: finalPrice })
              .on("transactionHash", function () {
                setConfirmTransaction(false);
                setMintingInProgress(true);
              })
              .on("confirmation", function () {
                // setNftMinted(true);
                // setConfirmTransaction(false);
                // setMintingInProgress(false);
                // setTimeout(() => {
                //   window.location.reload(false);
                // }, 5000);
              })
              .on("receipt", function () {
                setNftMinted(true);
                setConfirmTransaction(false);
                setMintingInProgress(false);
                setTimeout(() => {
                  window.location.reload(false);
                }, 5000);
              })
              .on("error", function (error, receipt) {
                if (error.code === 4001) {
                  setTransactionRejected(true);
                  setConfirmTransaction(false);
                  setMintingInProgress(false);
                } else {
                  setTransactionFailed(true);
                  setConfirmTransaction(false);
                  setMintingInProgress(false);
                }
              });
          }
        } else {
          setSaleLive(true);
        }
      } else {
        setswitchToMainnet(true);
      }
    } else {
      setEthereumCompatibleBrowser(true);
    }
  }

  const mintHandler = (mintCount) => {
    if (window.ethereum) {
      if (account) {
        mint(mintCount);
      }
      if (!account) {
        loadWeb3();
      }
    } else {
      window.location.href = `https://metamask.app.link/dapp/${window.location.host}`;
    }
  };

  const connectAccount = () => {
    if (window.ethereum) {
      if (!account) {
        loadWeb3();
      }
    } else {
      window.location.href = `https://metamask.app.link/dapp/${window.location.host}`;
    }
  };

  return (
    <div className="App relative">
      <Header loadWeb3={loadWeb3} account={account} />
      <Main
        loadWeb3={connectAccount}
        account={account}
        mint={mintHandler}
        maxSupply={maxSupply}
        totalSupply={totalSupply}
        // publicLimit={publicLimit}
        // presaleLimit={presaleLimit}
        displayPrice={displayPrice}
      />
      <Footer />
      <InformationModal
        open={saleLive}
        onClose={setSaleLive}
        title=" Sale is not live"
        text="Sale is not live yet. Please follow our discord for the updates"
      />
      <InformationModal
        open={lessMintAmountAlert}
        onClose={setLessMintAmountAlert}
        title="Oops"
        text="Atleast 1 Force Of Nature should be minted"
      />
      <InformationModal
        open={accessAccountDenied}
        onClose={setAccessAccountDenied}
        title="Oops"
        text="Request to access account denied!"
      />
      <InformationModal
        open={installEthereum}
        onClose={setInstallEthereum}
        title="Oops"
        text="Please install an Ethereum-compatible browser or extension like MetaMask to use this dApp!"
      />
      <InformationModal
        open={nftMinted}
        onClose={setNftMinted}
        title="Mint Successful"
        text="Thank you for the purchase. Your NFT will be available on OpenSea shortly"
      />
      <InformationModal
        open={nftMinting}
        onClose={setNftMinting}
        title="Information"
        text="Minting NFT!"
      />
      <InformationModal
        open={transactionRejected}
        onClose={setTransactionRejected}
        title="Error"
        text="Transaction Rejected!"
      />
      <InformationModal
        open={transactionFailed}
        onClose={setTransactionFailed}
        title="Error"
        text="Transaction Failed!"
      />
      <InformationModal
        open={switchToMainnet}
        onClose={setswitchToMainnet}
        title="Error"
        text="Please switch to mainnet to mint  Force Of Nature"
      />
      <InformationModal
        open={ethereumCompatibleBrowser}
        onClose={setEthereumCompatibleBrowser}
        title="Error"
        text="Please install an Ethereum-compatible browser or extension like MetaMask to use this dApp!"
      />
      <ConfirmationLoadingPopup
        open={confirmTransaction}
        title="Confirm Transaction"
        message="Confirm transaction to mint the NFT"
      />
      <ConfirmationLoadingPopup
        open={mintingInProgress}
        title="Minting In Progress"
        message="Please wait to get confirmation of the transaction from blockchain"
      />
    </div>
  );
}

export default App;
