import React, { useState } from "react";
import Logo from "../assets/images/Logo.png";
import { HiOutlineMenuAlt3 } from "react-icons/hi";

const Header = ({ account, loadWeb3 }) => {
  const [open, setOpen] = useState(false);

  const menuHandler = () => {
    setOpen((prev) => !prev);
  };

  return (
    <div className="bg-dark-500 relative text-white">
      <div className="container  py-4 flex justify-between items-center">
        <img src={Logo} alt="logo" />
        <button
          onClick={loadWeb3}
          className=" block py-2 px-5 uppercase font-bold rounded-sm bg-primary-400"
        >
          {account
            ? account.slice(0, 4) + "..." + account.slice(account.length - 4)
            : "CONNECT "}
        </button>
        {/* <ul className={` hidden md:flex items-center `}>
          {linkList.map((link, i) => (
            <li key={i}>
              <a href={link.link} className=" p-4 font-semibold uppercase">
                {link.text}
              </a>
            </li>
          ))}
          <li className="ml-4 grid grid-flow-col gap-x-2 justify-end items-center">
            <button
              onClick={loadWeb3}
              className=" block py-2 px-6 uppercase font-bold rounded-sm bg-primary-400"
            >
              {account
                ? account.slice(0, 8) +
                  "..." +
                  account.slice(account.length - 5)
                : "CONNECT "}
            </button>
          </li>
        </ul>
        <ul
          className={`mobile-menu ${
            open && "active"
          }  md:hidden items-center rounded-lg `}
        >
          {linkList.map((link, i) => (
            <li key={i}>
              <a
                href={link.link}
                onClick={menuHandler}
                className="block p-2 font-semibold uppercase"
              >
                {link.text}
              </a>
            </li>
          ))}
          <li>
            <a
              href={"https://forceofnaturemint-dev.netlify.app/"}
              target={"_blank"}
              rel="noreferrer"
              className="mb-6 block mx-auto py-2 px-6 uppercase font-bold rounded-sm bg-primary-400 "
            >
              Mint Now
            </a>
            <a
              href={"https://forceofnaturemarketplace-dev.netlify.app/"}
              target={"_blank"}
              rel="noreferrer"
              className="mb-6 block mx-auto py-2 px-6 uppercase font-bold rounded-sm bg-primary-400 "
            >
              Trade NFTs
            </a>
          </li>
        </ul>
        <button className="text-3xl md:hidden" onClick={menuHandler}>
          <HiOutlineMenuAlt3 />
        </button> */}
      </div>
    </div>
  );
};

export default Header;

const linkList = [
  {
    text: "Home",
    link: "#",
  },
  {
    text: "Roadmap",
    link: "#roadmap",
  },
  {
    text: "Story",
    link: "#story",
  },
  {
    text: "Team",
    link: "#team",
  },
];
