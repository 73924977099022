import { useState } from "react";
import videomain from "../assets/images/sideimage.mp4";
import videoutil from "../assets/images/video-utils.png";
import videoutil2 from "../assets/images/video-utils2.png";
import { FiMinus } from "react-icons/fi";
import { GoPlus } from "react-icons/go";
const Main = ({
  loadWeb3,
  mint,
  account,
  maxSupply,
  totalSupply,
  displayPrice,
}) => {
  const [count, setCount] = useState(1);

  const increaseHandler = () => {
    if (count < maxSupply - totalSupply) {
      setCount((prev) => prev + 1);
    }
  };
  const decreaseHandler = () => {
    if (count > 1) {
      setCount((prev) => prev - 1);
    }
  };

  // const mintHandler = () => {
  //   if (account) {
  //     mint(count);
  //   }
  //   if (!account) {
  //     loadWeb3();
  //   }
  // };
  const progress = (Number(totalSupply) / Number(maxSupply)) * 100;
  const width =
    progress > 0
      ? Math.floor(progress) + 1 >= 100
        ? 100
        : Math.floor(progress) + 1
      : 0;

  return (
    <div className=" py-20 pb-28">
      <div className="container">
        <h1 className="text-center text-4xl ">MINT</h1>
        <div className="grid grid-cols-1 md:grid-cols-2 mt-20 gap-10 items-center">
          <LeftComponent />
          <div className="text-primary-400">
            <p className="mt-2 text-white text-center mb-6 text-2xl">
              NFT mint price <b>{(displayPrice * count).toFixed(2)}</b> BNB
            </p>
            <div className="grid  grid-flow-col gap-4 text-white justify-center items-center">
              <button
                onClick={decreaseHandler}
                className="h-10 w-10 bg-primary-400 flex justify-center items-center  rounded-full text-xl"
              >
                <FiMinus />
              </button>
              <div className="text-4xl font-bold bg-white text-primary-400 h-14 w-14 flex items-center justify-center rounded-md">
                {count}
              </div>
              <button
                onClick={increaseHandler}
                className="h-10 w-10 bg-primary-400 flex justify-center items-center  rounded-full text-xl"
              >
                <GoPlus />
              </button>
            </div>

            <button
              onClick={() => mint(count)}
              className="bg-primary-400 text-white font-bold py-2 px-6 w-3/4 mx-auto block text-xl rounded-full mt-6"
            >
              {account ? "MINT NOW" : "CONNECT WALLET"}
            </button>
            <div className="mt-6 w-3/4  mx-auto">
              <div className="flex justify-between items-center px-2 text-white ">
                <p>{totalSupply} NFTs</p> <p>{maxSupply} NFTs</p>
              </div>
              <div className=" rounded-full w-full border-4 border-primary-400 overflow-hidden">
                <div
                  style={{ width: `${width}%` }}
                  className={`py-2 w-10 bg-white rounded-full`}
                ></div>
              </div>
              <p className="mt-2 text-white">Total Force of Nature Sold</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Main;

const LeftComponent = () => (
  <div className="p-3 relative">
    <img
      src={videoutil}
      className="absolute  -z-10 top-0 -translate-y-1/2 translate-x-full"
      alt=""
    />
    <img
      src={videoutil2}
      className="absolute  -z-10 bottom-0 translate-y-1/2 -translate-x-full right-20"
      alt=""
    />
    <div className="absolute left-0 top-0 h-20 w-20 border-t-[5px] border-l-[5px] border-primary-400"></div>
    <div className="absolute left-0 bottom-0 h-20 w-20 border-b-[5px] border-l-[5px] border-primary-400"></div>
    <div className="absolute right-0 top-0 h-20 w-20 border-t-[5px] border-r-[5px] border-primary-400"></div>
    <div className="absolute right-0 bottom-0 h-20 w-20 border-b-[5px] border-r-[5px] border-primary-400"></div>
    <video
      autoPlay={true}
      loop={true}
      muted={true}
      playsInline={true}
      className=""
    >
      <source src={videomain} type="video/mp4"></source>
    </video>
  </div>
);
